<template>
  <div>
    <vue-headful :title="pageheading" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">
        {{ pageheading.toLocaleUpperCase() }}
      </h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div id="body-content-area" style="padding: 0px; margin: 0px;">
        <div id="pagelayout" style="height: 100%; padding: 0px;">
          <div class="document">
            <iframe src="http://www.africau.edu/images/default/sample.pdf" frameborder="0" style="width: 100%; height: 100%;"></iframe>
          </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewReportAdminScreen',
  props: {
    model: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      pageheading: this.model.name + ' Preview'
    }
  },
  methods: {
    downloadReport () {
      // TODO [BD|05-20-2021] Can't really make until we have an API
    }
  }
}
</script>

<style scoped>
.document {
  background-color: #eee!important;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

#pagelayout {
  width: 100%;
  height: 100%;
}
</style>
